<template>
  <div class="container page">
    <van-nav-bar :title="$t('withdraw.with_center')" class="nav-bar">
      <template #left>
        <van-icon color="#fff" name="arrow-left" @click="back()"/>
      </template>
      <template #right>
        <span class="nav-right" @click="$router.push({path:'/WithdrawRecord'})">{{ $t("withdraw.with_record") }}</span>
      </template>
    </van-nav-bar>
    <div class="main">
      <div class="withdrawMoney">
        <span>{{ $t("withdraw.with_money") }} ({{ $t("reservation.unit") }})</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType">KRW</span>
            <van-field v-model="withdraw_money" type="number"/>
          </div>
          <span class="all" @click="allMoeny()">{{ $t("index.all") }}</span>
        </div>
        <div class="money" style="margin-top: 20px">
			<van-field v-model="password"  placeholder="출금 비밀번호를 입력해 주세요" type="password"/>
          <!-- <input v-model="password" class="password" placeholder="출금 비밀번호를 입력해 주세요" type="password"/> -->
        </div>
        <div class="information">
          <div class="description">
            <van-popover v-model="showPopover" trigger="click">
              <div class="popover-body" style="padding: 10px;">
                <p>
                  1.{{ $t("withdraw.single_limit") }}{{ $t("withdraw.low") }}{{ this.withdrawRole.min }}{{ $t("withdraw.heigh") }}{{ this.withdrawRole.max }}{{ $t("reservation.unit") }}</p>
                <p>2.{{ $t("withdraw.with_num") }}{{ this.withdrawRole.num }}{{ $t("withdraw.number") }}</p>
                <p>3.{{ $t("withdraw.with_tip") }}</p></div>
              <template #reference @click="withdrawInfo()">
                <van-icon name="info-o"/>
                {{ $t("withdraw.limit_desc") }}
              </template>
            </van-popover>
          </div>

          <div class="balance">
            <span>{{ $t("my.balance") }}：</span>
            <span class="number">{{ this.userInfo.money }}{{ $t("reservation.unit") }}</span>
          </div>
        </div>
      </div>
      <van-button class="withdraw_btn" type="default" @click="doWithdraw()"> {{ $t("withdraw.immediately_withdraw") }}
      </van-button>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopover: false,
      withdraw_money: "",
      userInfo: {},
      withdrawRole: {},
      password: "",
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.name = res.data.name;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getUserWithdrawRole() {
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_role'
      }).then(res => {
        if (res.code === 200) {
          this.withdrawRole = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    allMoeny() {
      this.withdraw_money = this.userInfo.money;
    },
    doWithdraw() {
      if (this.withdraw_money <= 0) {
        this.$toast(this.$t("setting.correct_money"));
        return false;
      } else {
        this.$http({
          method: 'post',
          data: {money: this.withdraw_money, password: this.password},
          url: 'user_set_withdraw'
        }).then(res => {
          if (res.code === 200) {
            // location. reload()
            this.$toast(res.msg);
            this.getUserInfo();
            this.getUserWithdrawRole();
          } else if (res.code === 401) {
            this.$toast(res.msg);
          }
        });
      }
    },
    withdrawInfo() {
      this.showPopover = true;
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({path: '/Login'});
    } else {
      this.getUserInfo();
      this.getUserWithdrawRole();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.password {
  width: 100%;
  padding: 20px 16px; /* 增加内边距 */
  border: 1px solid #ccc; /* 添加边框 */
  border-radius: 4px; /* 圆角边框 */
  outline: none; /* 去掉点击时的边框样式 */
  font-size: 18px; /* 调整字体大小 */
  box-sizing: border-box; /* 确保内边距不会影响宽度 */
  background-color: #f9f9f9; /* 背景色 */
  transition: border-color 0.3s ease; /* 添加过渡效果 */
}

.van-cell {
  font-size: 35px;
  line-height: 80px;
}

.container p {
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}

.container .main {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f5;
  height: calc(100% - 50px);
  position: relative;
}

.container .main .withdrawMoney {
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 35px;
  background-color: #fff;
}

.container .main .withdrawMoney span {
  padding: 10px 0;
}

.container .main .withdrawMoney .money {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f5;
}

.container .main .withdrawMoney .money .moneyNumber {
  font-size: 50px;
  display: flex;
  flex-direction: row;
}

.container .main .withdrawMoney span {
  padding: 10px 0;
}

.container .main .withdrawMoney .money .all {
  color: #d10404;
}

.container .main .withdrawMoney .money .moneyNumber .van-cell {
  font-size: 50px;
  padding: 0 !important;
}

.container .main .withdrawMoney .information {
  padding-bottom: 30px;
}

.container .main .withdrawMoney .information .description {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}

.container .main .withdrawMoney span {
  padding: 10px 0;
}

.container .main .withdrawMoney .information .balance .number {
  color: #d10404;
}

.withdraw_btn {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(90deg, #988fba, #f487e0);
}
</style>
